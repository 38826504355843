<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.task") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="task" class="grid gap-4">
      <div class="card p-4">
        <div
          class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div>
            <label class="block">{{ t("labels.name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <div v-else>
              <span>{{ task.name }}</span>
            </div>
          </div>
          <div>
            <label class="block">{{ t("labels.start_date") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ getLocaleDate(locale, task.startDate) }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.end_date") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span
              v-else
              :class="{
                'text-rose-600': closeToDeadline(task.endDate, task.status),
              }"
              >{{ getLocaleDate(locale, task.endDate) }}</span
            >
          </div>
          <div v-if="task.maintenancePerson">
            <label class="block">{{ t("labels.maintenance_person") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ task.maintenancePerson }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.status") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else> {{ t("status." + task.status) }}</span>
          </div>
          <div v-if="task && task.requestId">
            <label class="block">{{ t("labels.request") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else> {{ getRequestInfo(task.requestId) }}</span>
          </div>
          <div v-if="task && task.projectId">
            <label class="block">{{ t("labels.project") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else> {{ getProjectName(task.projectId) }}</span>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div
          class="flex justify-end"
          v-if="route.path.includes('notifications')"
        >
          <form-button
            :command="
              () =>
                router.push(
                  `/estates/${route.params.estateId}/notifications/${route.params.notificationId}/tasks/${task.id}/edit`
                )
            "
            :disabled="waiting"
            id="task-edit-button"
            label="edit"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
        <div class="flex justify-end" v-else>
          <form-button
            :command="
              () => {
                if (projectId) {
                  router.push(
                    `/projects/${projectId}/${phaseName}/${task.id}/edit`
                  );
                } else {
                  router.push(`/tasks/${task.id}/edit`);
                }
              }
            "
            :disabled="waiting"
            id="task-edit-button"
            label="edit"
            :textVariant="company.primaryText"
            type="button"
            :variant="company.primary"
          />
        </div>
      </div>
      <div>
        <h2>{{ t("headings.notes") }}</h2>
        <div class="card">
          <custom-table
            :fields="fields"
            :items="task.notes ? task.notes : []"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="createdAt"
            :ascending="false"
            :sortFields="sortFields"
            @row-click="
              () =>
                router.push(
                  `/estates/${route.params.estateId}/notifications/${route.params.notificationId}/tasks/${task.id}/notes/${id}`
                )
            "
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="() => router.push(`/tasks/${task.id}/notes/create`)"
                  id="header-note-create-button"
                  label="create_note"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(createdAt)="{ value }">
              {{ getLocaleDate(locale, value) }}
            </template>
            <template #cell(userId)="{ value }">
              {{ getUserName(value, personnel) }}
            </template>
          </custom-table>
        </div>
      </div>
      <div>
        <h2>{{ t("labels.images") }}</h2>
        <div class="card">
          <custom-table
            :fields="fileFields"
            :items="task.images || []"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="fileSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="fileSortFields"
          >
            <template #cell(actions)="{ item }">
              <div class="flex justify-end gap-2">
                <form-button
                  :command="() => openFile(item.path)"
                  id="open-file-button"
                  label="open"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
              </div>
            </template>
          </custom-table>
          <hr />
          <div class="flex justify-end p-4">
            <form-filepicker
              accept="image/jpeg, image/png"
              :disabled="waiting"
              id="image-upload"
              label="upload_image"
              :multiple="false"
              :textVariant="company.primaryText || 'light'"
              :variant="company.primary || 'indigo'"
              v-model="image"
            />
          </div>
        </div>
      </div>
      <div>
        <h2>{{ t("labels.files") }}</h2>
        <div class="card">
          <custom-table
            :fields="fileFields"
            :items="task.files || []"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="fileSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="fileSortFields"
          >
            <template #cell(actions)="{ item }">
              <div class="flex justify-end gap-2">
                <form-button
                  :command="() => openFile(item.path)"
                  id="open-file-button"
                  label="open"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
              </div>
            </template>
          </custom-table>
          <hr />
          <div class="flex justify-end p-4">
            <form-filepicker
              accept="*"
              :disabled="waiting"
              id="file-upload"
              label="upload_file"
              :multiple="false"
              :textVariant="company.primaryText || 'light'"
              :variant="company.primary || 'indigo'"
              v-model="file"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormFilepicker from "../components/form/FormFilepicker.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { closeToDeadline, getLocaleDate } from "../utils/dateUtils";
import { getEstateName } from "../utils/estateUtil";
import { getUserName } from "../utils/userUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton, FormFilepicker, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const projectId = ref(route.params?.projectId);
    const phaseName = ref(route.params?.phaseName);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const fileOrImage = computed(() => store.state.file.file);
    const notifications = computed(
      () => store.state.notification.notifications
    );
    const projects = computed(() => store.state.project.projects);
    const task = computed(() => store.state.task.task);
    const user = computed(() => store.state.authentication.user);
    const personnel = computed(() => store.state.user.personnel);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.notification.waiting) return true;
      if (store.state.project.waiting) return true;
      if (store.state.task.waiting) return true;
      if (store.state.user.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "text",
        label: "text",
      },
      {
        key: "userId",
        label: "user",
      },
    ]);
    const searchFields = ref(["createdAt", "text"]);
    const sortFields = ref(["createdAt"]);

    const fileFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);
    const fileSearchFields = ref([]);
    const fileSortFields = ref([]);

    const getProjectName = (projectId) => {
      const found = projects.value.find((project) => project.id == projectId);
      return found ? found.name : "";
    };
    const getRequestInfo = (requestId) => {
      const found = notifications.value.find(
        (notification) => notification.id == requestId
      );
      return found
        ? getEstateName(found.estateId, estates.value) + " - " + found.apartment
        : "";
    };

    const file = ref(null);

    const openFile = (path) => {
      store.dispatch("file/getFile", path);
    };

    const image = ref(null);

    onMounted(() => {
      store.dispatch("task/getTask", route.params.taskId);
      if (!personnel.value.length) {
        store.dispatch("user/getPersonnel", user.value.companyId);
      }
    });

    watch(
      () => task.value,
      (task) => {
        if (!notifications.value.length && task.requestId) {
          store.dispatch("notification/getNotifications", user.value.companyId);
          if (!estates.value.length) {
            store.dispatch("estate/getEstates", user.value.companyId);
          }
        }

        if (!projects.value.length && task.projectId) {
          store.dispatch("project/getProjects", user.value.companyId);
        }
      }
    );

    watch(
      () => image.value,
      (image) => {
        if (image) {
          store.dispatch("task/addImage", {
            companyId: company.value.id,
            file: image,
            taskId: task.value.id,
          });
        }
      }
    );

    watch(
      () => file.value,
      (file) => {
        if (file) {
          store.dispatch("task/addFile", {
            companyId: company.value.id,
            file: file,
            taskId: task.value.id,
          });
        }
      }
    );

    watch(
      () => fileOrImage.value,
      (fileOrImage) => {
        if (fileOrImage) {
          window.open(fileOrImage, "_blank");
        }
      }
    );

    return {
      closeToDeadline,
      company,
      fields,
      file,
      fileFields,
      fileSearchFields,
      fileSortFields,
      getLocaleDate,
      getProjectName,
      getRequestInfo,
      getUserName,
      image,
      locale,
      notifications,
      openFile,
      personnel,
      phaseName,
      projectId,
      projects,
      route,
      router,
      searchFields,
      sortFields,
      t,
      task,
      waiting,
    };
  },
};
</script>
